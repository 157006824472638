export const createMenuByHeaders = ({
  menuId = "menu",
  menuClassLinks = ".sticky-menu a",
  headerSelector = ".terms h2"
}) => {
  const menu = document.getElementById(menuId)
  const headings = document.querySelectorAll(headerSelector)
  headings.forEach(heading => {
    const id = heading.id
    const text = heading.textContent
    if (!id || !text) return
    const li = document.createElement("li")
    const a = document.createElement("a")
    a.href = `#${id}`
    a.textContent = text
    li.appendChild(a)
    menu?.appendChild(li)
  })
  document.querySelectorAll(menuClassLinks).forEach(anchor => {
    anchor.addEventListener("click", function (e) {
      e.preventDefault()
      const href = anchor?.getAttribute("href")
      if (href) {
        document.querySelector(href)?.scrollIntoView({
          behavior: "smooth"
        })
      }
    })
  })
}