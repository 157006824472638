import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "terms" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StickyMenu = _resolveComponent("StickyMenu")!
  const _component_PrivacyPolicyContent = _resolveComponent("PrivacyPolicyContent")!
  const _component_ScrollTop = _resolveComponent("ScrollTop")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_StickyMenu, { class: "terms__menu" }),
    _createVNode(_component_PrivacyPolicyContent, { isModal: false }),
    _createVNode(_component_ScrollTop, { class: "terms__scroll-top" })
  ]))
}