
import { createMenuByHeaders } from "@/utils/menuByHeaders"
import StickyMenu from "@/components/StickyMenu/StickyMenu.vue"
import ScrollTop from "@/components/ScrollTop/ScrollTop.vue"
import { Options, Vue } from "vue-class-component"
import PrivacyPolicyContent from "@/components/PrivacyPolicyContent.vue"

@Options({
  components: {
    StickyMenu,
    ScrollTop,
    PrivacyPolicyContent
  }
})

export default class PrivacyPolicy extends Vue {
  mounted(): void {
    createMenuByHeaders({
      menuId: "menu",
      menuClassLinks: ".sticky-menu a",
      headerSelector: ".terms h2"
    })
  }
}
